.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paperWidthMd {
      width: 600px;
      background: none;
    }
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        background: #1b2a44;
        color: var(--ion-text-light);
        text-align: center;
        height: 60px;
        border-radius: 16px 16px 0 0;
        .MuiTypography-root {
          color: var(--text-white) !important;
          font-family: var(--headers-font-family) !important;
        }
      }

      .MuiIconButton-root {
        position: absolute;
        top: 8px;
        right: 12px;
        opacity: 1;
      }

      .MuiDialogContent-root {
        background: var(--dialog-content-background);
        padding: 0px 12px !important;
        overflow-y: initial !important;
        .MuiButton-containedPrimary {
          background-color: var(-ion-text-color-green) !important;
          color: var(--ion-text-light) !important;
          text-transform: none;
          font-weight: 400;
          margin-bottom: 20px;
          font-size: 14px;
          padding: 10px 0;
          width: 100%;
          border-radius: 50px;
        }

        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}
