.admin-right-container {
  background: var(--ion-bg-table);
  border-radius: 24px;
  padding: 12px;
  @media screen and (max-width: 720px) {
    padding: 10px;
  }
}
.reports-ctn {
  .commition-tb-bg {
    background: var(--ion-main-body-bg);
    padding: 16px !important;
    border-radius: 16px !important;
  }
  //background-color: var(--ion-main-body-bg);
  border-radius: 16px;
  //padding: 16px;

  .header-ctn {
    padding: 0px 0px;
    margin-top: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 720px) {
      display: initial;
    }
    &.down-ctn {
      margin-bottom: 20px;
    }
    .title-arrow {
      display: flex;
      @media screen and (max-width:720px){
        width: 100%;
      }
      .reports-icon {
        color: white;
        fill: white;
      }
    }

    .hedaer-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .page-title {
      color: var(--ion-text-light) !important;
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      padding: 0px;
      letter-spacing: 0.01em;
      margin-right: auto;
      line-height: 0;
      width: 100%;
      &.ptitleauto {
        width: auto;
      }
    }
  }

  .filters-row {
    ion-select,
    .date-filter,
    .select-filter,
    .searchbar-input-container {
      border: 1px solid var(--filter-input-border);
      --box-shadow: 0;
      background: var(--filter-input-bg);
      color: var(--ion-text-light);
    }

    .MuiFormControl-marginNormal {
      margin-top: 0;
      margin-bottom: 0;
    }

    .MuiInputBase-root .MuiInputBase-input {
      padding: 0 4px 0 6px;
      color: var(--ion-text-light);
      font-size: 10px;
    }

    .filter-label {
      color: var(--ion-text-light);
      font-size: 14px;
      font-family: var(--headers-font-family);
      position: absolute;
      left: 10px;
      top: 2px;
      color: var(--ion-text-green-color);
      font-size: 10px;
    }

    .search-filter {
      max-height: 40px;
      max-width: 228px;
      margin-right: 10px;
      @media only screen and (max-width: 920px) {
        max-width: 100%;
        max-height: initial;
      }

      .searchbar-input-container {
        border-radius: 3px;
        --box-shadow: 0;
        input {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    .MuiFormControl-root,
    ion-select {
      max-width: 115px;
      background: var(--filter-input-bg) !important;
      border: 1px solid var(--filter-input-border);
      width: 115px;
      height: 40px;
      margin-bottom: 0;
      @media screen and (max-width:720px){
        max-width: 100%;
        width: 100%;

      }
    }
  }

  .content-ctn {
    padding-top: 20px;
    border-radius: 6px;

    .table-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            // .MuiTableRow-root:nth-child(even) {
            //   background: var(--ion-card-background-contrast);
            // }
            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background-light);
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-light);
              }
            }
            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background-light);
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
          }
        }
      }
    }
  }

  .header-ctn,
  .content-ctn {
    .points-type-toggle {
      background-color: transparent;
      border: 1.5px solid #636c89;
      display: inline-flex;
      height: 40px;
      border-radius: 4px;
      .MuiBottomNavigationAction-root {
        color: var(--ion-text-color);
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;
        &.Mui-selected {
          color: var(--ion-text-light);
          background: var(--ion-color-success);
        }

        .MuiBottomNavigationAction-label {
          font-size: 14px !important;
        }
      }
    }
  }

  .tbl-ctn {
    border-collapse: separate;
    border-spacing: 0 3px;
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableHead-root {
          //background: rgba(27, 42, 68, 1) !important;

          .MuiTableCell-head {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            font-family: var(--headers-font-family);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
            text-transform: uppercase;
            padding: 0 16px 16px;
            &:first-child {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }
            &:last-child {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
        }
      }
    }
  }

  .tbl-ctn:not(.no-hov-style) {
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableBody-root {
          .MuiTableRow-root:hover {
            background-color: rgba(255, 167, 36, 0.2) !important;
          }
        }
      }
    }
  }

  .no-data-row {
    background: #202b3e;
    width: 100%;
    border-bottom: 0px !important;
    color: var(--ion-text-color-contrast);
    height: 40px;
    padding-top: 15px;
    padding-left: 15px;
    font-size: 16px;
    font-family: var(--headers-font-family);
    border-radius: 6px;
    text-align: center;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.h-100 {
  height: 100%;
}
.table-total-container {
  padding: 12px;
}
@media (min-width: 992px) {
  .reports-ctn {
    width: 100%;
  }
}
.reports-back-icon{
  .MuiSvgIcon-root{
   display: none;
  }
}
@media only screen and (max-width: 720px) {
  .admin-right-container{background: none;padding: 0;}
  .admin-module-ctn {
    padding-bottom: 10px;
    .reports-ctn {
      margin-top: 0 !important;
      .header-ctn {
        margin-bottom: 10px;
        .page-title {
          padding-bottom: 0;
          font-size: 16px;
          background: var(--ion-bg-table);
          display: flex;
          padding: 8px 0 8px 10px;
          .reports-back-icon{
            .MuiSvgIcon-root{
              display: block;
              color: #fff;
              margin-right: 5px;
            }
          }
        }
  
      }
    }
    .market-risk-block {
      ion-card-content {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .reports-ctn {
    margin: 10px 0px !important;
    .header-ctn,
    .content-ctn {
      // padding: 10px 0px;
      .points-type-toggle {
        border: 1.5px solid #636c89;
        height: 30px;
        border-radius: 4px;
        margin-top: 2px;
        .MuiBottomNavigationAction-root {
          line-height: 17px;
          min-width: 52px;

          .MuiBottomNavigationAction-label {
            font-size: 12px !important;
          }
        }
      }
    }

    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .page-title {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 20px;
      }
      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }
        .summary-expand-toggle {
          text-align: center;
          .summary-expand-toggle-btn {
            color: var(--ion-text-light);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;
            font-family: var(--headers-font-family);
            ion-icon {
              color: var(--ion-text-light);
              height: 18px;
            }
          }
        }
      }
    }
    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
          font-family: var(--headers-font-family);
        }
      }

      .filters,
      .filters-row {
        font-size: 12px;
        font-family: var(--headers-font-family);
        color: var(--ion-text-light);
        ion-select,
        .MuiFormControl-marginNormal {
          height: 32px !important;
          margin-top: 0;
        }

        .MuiInputBase-root .MuiInputBase-input {
          padding: 0 12px;
          font-size: 12px;
        }

        .search-filter {
          display: none;
        }
      }
    }
  }
}
