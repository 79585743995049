.login-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;

  .title {
    font-size: 30px;
    color: var(--ion-color-primary);
    font-weight: bold;
    text-align: center;
  }
  .forgot-pwd-link {
    margin-top: 24px;
    margin-bottom: 0px;
    font-size: 16px;
    color: var(--ion-forgot-color);
    text-align: center;
    cursor: pointer;
  }

  .usr-input,
  .code-input,
  .pwd-input {
    display: inline-grid;
    .MuiOutlinedInput-adornedEnd {
      height: 52px;
    }
  }

  .login-form-btn {
    margin-top: 15px !important;
    max-width: 100%;
    height: 48px;
    font-family: 'Alata';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    //color: var(--ion-name-cell-color);
    
  }

  .login-form-ctn .MuiInputBase-input {
    font-size: 16px;
    font-family: 'Alata';
    color: rgba(0, 0, 0);;
  }

  .MuiIconButton-root {
    padding: 0;
    opacity: 0.4;
  }

  .login-err-msg {
    color: red;
    margin-top: 20px;
    text-align: center;
  }
  .usr-input {
    margin-top: 30px;
  }
  .pwd-input,
  .code-input {
    margin-top: 20px;
  }

  .user-name,
  .pwd-field {
    max-width: 100%;
    height: 48px;
    border-radius: 12px;
  }

  .bottom-link {
    margin-top: 24px;
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--ion-text-color);
    text-align: center;

    .register-link {
      color: var(--ion-color-primary);
      cursor: pointer;
    }
  }
  .login-form-restrictions-info {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 18px;
    margin-top: 12px;
    .restrictions-info-ctn {
      display: inline-flex;
      align-items: center;
      .restrictions-info-btn {
        .btn-text {
          display: none;
        }
        .age-text {
          font-size: 16px;
          line-height: 20px;
          padding: 8px 3px;
          color: var(--ion-text-color-contrast);
          border: 1px solid var(--ion-text-color-contrast);
          ion-icon {
            color: var(--ion-text-color-contrast);
          }
        }
        .MuiSvgIcon-root {
          font-size: 40px;
          color: var(--ion-text-color-contrast);
          .MuiOutlinedInput-input{color: #000 !important;}
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .login-form-ctn {
    padding: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    .usr-input {
      margin-top: 10px;
    }
    .pwd-input,
    .code-input {
      margin-top: 20px;
    }

    .user-name,
    .pwd-field,
    .login-form-btn {
      width: 100%;
      color: var(--ion-name-cell-color);
    }
  }
}
@media (min-width: 350px) and (max-width: 420px) {
  .login-form-ctn {
    .usr-input {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .pwd-input,
    .code-input {
      margin-top: 20px;
    }

    .user-name,
    .pwd-field,
    .login-form-btn {
      width: 100% !important;
      color: var(--ion-name-cell-color);
    }
  }
}

@media (min-width: 220px) and (max-width: 349px) {
  .login-form-ctn {
    padding-left: 10%;
    padding-right: 5%;
    .usr-input {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .pwd-input,
    .code-input {
      margin-top: 20px;
    }

    .user-name,
    .pwd-field,
    .login-form-btn {
      width: 230px !important;
    }
  }
}


