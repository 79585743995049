.table-ctn {
  width: 100%;

  .MuiPaper-root {
    margin-top: 16px;
    box-shadow: none;
    border-color: none;
    width: 100%;
    border-radius: 6px;
    background-color: transparent;

    .MuiTable-root {
      .MuiTableRow-root {
        background: var(--ion-card-background);
        border: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.1);
      }

      .MuiTableHead-root {
        height: 18px;

        .MuiTableRow-root {
          background: var(--ion-color-primary);
          color: var(--ion-text-dark);
          .MuiTableCell-root {
            // border: 0px;
            font-family: 'Alata';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            white-space: nowrap;
            .MuiIconButton-root {
              opacity: 1 !important;
            }

            .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
              .MuiTableSortLabel-icon {
              color: #fff !important;
            }
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-body {
          color: rgba(255, 255, 255, 0.6);
          border: 0px;
          cursor: default;
          white-space: nowrap;
          font-size: 14px;
        }
        .MuiSvgIcon-root {
          color: var(--ion-text-color-contrast);
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .table-ctn {
    .MuiPaper-root {
      margin-top: 16px;
      .MuiTable-root {
        .MuiTableHead-root {
          .MuiSvgIcon-root {
            font-size: 20px;
          }
        }
        .MuiTableBody-root {
          .MuiTableCell-body {
            font-size: 12px;
            min-height: 42px;
          }
          .MuiSvgIcon-root {
            font-size: 18px;
            margin-bottom: -2px;
          }
        }
      }
    }
  }
}

.tbl-ctn {
  width: 100%;
  .MuiPaper-root {
    margin-top: 5px;
    box-shadow: none;
    border-color: none;
    width: 100%;
    border-radius: 6px;
    background-color: transparent;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
      border-radius: 10px !important;
      background-color: #999 !important;
      height: 2px;
    }

    &::-webkit-scrollbar {
      width: 0px !important;
      background-color: #000 !important;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
      background-color: #2e3e5d !important;
    }

    .MuiTableRow-root {
      border: none;
    }
    .MuiTableBody-root .MuiTableRow-root {
      background: var(--report-data-bg);
    }

    // .MuiTableRow-root {
    //   background: rgba(207, 207, 207, 0.2);
    // }

    // .MuiTableRow-root {
    //   background: rgba(207, 207, 207, 0.2);
    // }

    .MuiTableHead-root {
      height: 18px;

      .MuiTableRow-root {
        // border-bottom: 1px solid var(--filter-controls-border) !important;

        .MuiTableCell-root {
          color: rgba(255, 255, 255, 0.5);
          border: 0px;
          font-family: Alata;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 106.7%;
          white-space: nowrap;

          .MuiIconButton-root {
            opacity: 1 !important;
          }

          .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
            .MuiTableSortLabel-icon {
            color: #fff !important;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-body {
        color: var(--ion-text-color-contrast);
        border: 0px;
        cursor: default;
        white-space: nowrap;
        font-size: 14px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: var(--headers-font-family);
        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        .col-header {
          font-weight: 600;
        }

        .col-meta {
          opacity: 0.6;
          font-size: 12px;
        }
      }
      .MuiSvgIcon-root {
        color: var(--ion-text-color-contrast);
      }

      .back-odd-row {
        background-color: var(--back-odd-background);
        border-bottom: 1px solid #ffffff;
      }

      .lay-odd-row {
        background-color: var(--lay-odd-background);
        border-bottom: 1px solid #ffffff;
      }

      .profit-bet {
        color: var(--ion-color-success);
      }

      .loss-bet {
        color: var(--ion-color-lost);
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .tbl-ctn .MuiPaper-root {
    //margin-top: 20px;
  }
}
