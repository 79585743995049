$assetPath: '../../assets/images/common';
.bg {
  background-color: var(--ion-main-body-bg);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .card {
    // background-color: var(--ion-background-secondary);
    // background: linear-gradient(
    //   -115deg,
    //   rgba(var(--ion-color-primary-rgb), 0.7) -15%,
    //   rgba(var(--ion-background-secondary-rgb), 0.4) 45%,
    //   rgba(255, 255, 255, 0.2) 101%
    // );
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    width: 400px;
    background: none;
    border-radius: 24px;
    .login-mob-logo {
      display: none;
      width: 75px;
      margin: 0 auto 20px;
      @media screen and (max-width: 720px) {
        display: block;
      }
    }
    .logo-div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      padding: 15px 10px;
      .border {
        width: 100%;
        border-right: 1px solid var(--input-background-color);
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          width: var(--login-xl-logo-width);
        }
      }
    }
    .login-form {
      justify-content: center;
      width: 100%;
      background: var(--ion-login-bg);
      border-radius: 24px 24px 0 0;
      .title {
        color: var(--login-title-color);
        font-family: var(--headers-font-family);
      }

      .mob-logo-div {
        display: none;
      }
    }
    .submitbtn {
      background: var(--ion-login-signupbg);
      border-radius: 0 0 24px 24px;
      padding: 20px 0;
      text-align: center;
      color: var(--ion-text-light-color);
      span {
        color: var(--ion-main-bg-color);
      }
    }
    .input-label {
      color: var(--ion-login-input-bg) !important;
    }
    .MuiInputBase-root {
      border: 1px solid var(--ion-color-primary-contrast);
      //background-color: var(--ion-text-field-bg) !important;
      border-radius: 12px;
      .MuiInputBase-input {
        // color: var(--ion-color-primary-contrast) !important;
        //background-color: var(--input-background-color) !important;
      }
    }

    .MuiButton-contained {
      background-color: var(--ion-main-bg-color) !important;
      border-radius: 50px;
      color: var(--ion-text-light) !important;
      font-weight: normal;
      justify-content: center;
    }
    .MuiButton-label {
      //color: var(--ion-color-text-contrast) !important;
      //background-color: var(--ion-color-primary) !important;
      font-weight: bold;
      .MuiButton-endIcon {
        color: var(--ion-color-text-contrast) !important;
      }
    }
  }
}
.bg .card .login-form .MuiInputBase-root {
  border: none;
  background: #eaeef3;
  color: black;
  .MuiOutlinedInput-input {
    color: black !important;
    &:placeholder {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }
  .MuiSvgIcon-root {
    color: var(--eye-icon-color);
  }
}
@media (max-width: 720px) {
  .bg {
    // background-image: url(#{$assetPath}/mobile.png);
    // background-color: var(--ion-background);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    -moz-background-size: cover;
    -o-background-size: cover;

    .card {
      transform: initial;
      background-size: cover;
      display: block;
      width: 100%;
      position: initial;
      padding: 80px 15px 0px;
      .logo-div {
        display: block;
      }
      .login-form {
        justify-content: center;
        width: 100%;
        padding: 20px 12px 30px;
        border-radius: 16px;
        .mob-logo-div {
          padding-top: 20px;
          .border {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;

            .logo {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .bg {
    background-color: var(--ion-main-body-bg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .card {
      border-radius: 10px;
      top: 50%;
      left: 50%;

      background-size: cover;
      display: block;

      .logo-div {
        display: none;
      }
      .login-form {
        justify-content: center;
        width: 100%;

        .mob-logo-div {
          display: flex;
          justify-content: center;
          padding-top: 10px;
          .border {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}
