ion-card {
  background: none;
  color: var(--ion-card-text-color);
  border: none !important;
  border-radius: 24px;
  box-shadow: none;
  ion-card-header {
    background: none;
    color: var(--ion-card-text-color);

    ion-card-title {
      color: var(--ion-card-text-color);
    }
  }

  ion-card-header {
    //background: var(--ion-card-background);
    color: var(--ion-card-text-color);
  }
}

.data-info-card {
  border: 1px solid rgba(var(--ion-text-light-rgb), 0.1);
  border-radius: 6px;
  background: var(--ion-card-background-contrast);
  padding: 16px;
  font-family: 'Alata';
  letter-spacing: 0.01em;
  margin-left: 3px;
  margin-right: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;

  .card-label {
    color: var(--ion-text-color);
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  .card-content {
    color: var(--ion-text-color-contrast);
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.01em;
  }
}

@media only screen and (max-width: 576px) {
  .data-info-card {
    .card-label {
      font-size: 16px;
      line-height: 19px;
    }

    .card-content {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.info-card {
  border: 1px solid var(--border-color);
  border-radius: 20px;
  background: var(--ion-balance-bg);
  font-family: var(--headers-font-family);
  letter-spacing: 0.01em;
  margin-left: 3px;
  margin-right: 3px;
  display: flex;
  justify-content: space-between;
  cursor: default;
  &.flex-column {
    flex-direction: column;
    align-items: center;
  }
  sqqwewsmsz &.flex-row {
    flex-direction: row;
  }

  .card-label {
    color: var(--ion-text-green-color);
    padding-bottom: 15px;
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: var(--headers-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .card-content {
    color: var(--ion-text-light);
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: center;
    font-family: var(--headers-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

// Mini tabs and mobile
@media screen and (max-width: 720px) {
  .info-card {
    &.mb-size {
      padding: 8px;

      .card-label {
        font-size: 12px;
        font-weight: 600;
        padding-bottom: 8px;
      }

      .card-content {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &.flex-row {
      flex-direction: column;
    }
  }
}
