.popover-content {
  &.sc-ion-popover-md {
    background: var(--ion-dashboard-bg);
  }
}

// IonSelect
ion-select {
  width: 230px;
  height: 30px;
  padding: 15px 16px 14px 16px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid var(--filter-input-border);
  background: var(--filter-input-bg);
  color: var(--ion-text-light);
  font-family: var(--headers-font-family);
}
.item-native::after {
  color: #fff !important;
}
.ion-untouched .item-native::after {
  color: #fff !important;
}

ion-select-popover {
  background: var(--select-popover-background);
  ion-list {
    padding: 0px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    ion-item {
      --inner-border-width: 0 0 0 0 !important;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.12);
        color: var(--ion-color-light);
      }
      ion-radio {
        opacity: 0;
      }
      &:hover {
        color: var(--ion-color-light) !important;
        background: var(--table-field-background);
        // border-left: 4px solid var(--ion-color-primary);
        &:after {
          color: var(--ion-color-light) !important;
        }
      }
      &.item-radio-checked {
        color: var(--ion-text-light);
        border-left: 4px solid var(--ion-text-green-color);
        background: var(--table-field-background);
      }
      .item-native::after {
        color: #fff !important;
        font-family: var(--headers-font-family);
      }
      &.item .sc-ion-label-md-h {
        color: #fff !important;
        font-family: var(--headers-font-family);
      }
    }
  }
}

// MaterialUI Select
.MuiSelect-root {
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  background: none !important;
}
