@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import '../src/assets/global_styles/index.scss';

.router-ctn {
  width: 100%;
}
.ddd {
  justify-content: initial;
  background: var(--ion-main-body-bg);
}

body {
  font-family: 'Alata';
}
.dev-tools-msg-modal {
  .modal-content-ctn {
    background: var(--ion-background) !important;
    border: 5px solid var(--ion-color-primary);
    .dev-tools-warning-msg {
      padding: 20px;
      font-size: 24px;
      color: #fff;
    }
  }
}

.MuiTypography-body1 {
  font-family: 'Alata' !important;
}
.MuiTypography-body2 {
  font-family: 'Alata' !important;
}

.MuiTableCell-body {
  color: white !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  font-family: var(--headers-font-family) !important;
}
