.change-pwd-form-ctn {
  height: 100%;

  .usr-input,
  .pwd-input {
  }
  .user-name {
    width: 350px;
    margin-bottom: 10px;
  }
  .submit-form-btn {
    margin-bottom: 20px;
    width: 60%;
    max-width: 341px;
    height: 48px;
    font-family: 'Alata';
    font-size: 16px;
    line-height: 19px;
    background: var(--ion-main-bg-color) !important;
    color: var(--ion-text-light) !important;
    border-radius: 50px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18px;
    text-transform: capitalize;

    @media screen and (max-width: 720px) {
      font-size: 12px;
      height: 36px;
      max-width: initial;
      width: 60% !important;
      justify-content: center;
    }
  }

  .accept-terms-input {
    padding-top: 14px;
    max-width: 341px;

    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;

      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--ion-text-light);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--ion-text-light) !important;
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
// border-radius: 0px 0px 20px 20px;
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}

.user-name,
.pwd-field {
  max-width: 341px;
  height: 48px;
  background-color: var(--table-field-background);
  border: 1px solid var(--filter-input-border) !important;
}

.input-tooltip {
  color: var(--ion-text-light);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 298px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    max-width: 100%;
    width: 100% !important;
  }
}
