@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './search_filter.scss';
@import './reports.scss';

@import url('https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@300;400;500;600;700&family=Montserrat:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap');

// div,
// span,
// p,
// ion-select,
// ion-select-option,
// ion-label,
// ion-button,
// td,
// th {
//   font-family: Jost !important;
// }

// .web-view {
//   @media screen and (max-width: 720px) {
//     display: none !important;
//   }
// }

// .mob-view {
//   @media screen and (min-width: 720px) {
//     display: none !important;
//   }
// }

/* Backdrop styles */
.MuiBackdrop-root {
  background: rgba(72, 99, 155, 0.85) !important;
}
table {
  border-collapse: separate !important;
  border-spacing: 0 3px !important;
}
/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiFormControl-root,
.MuiOutlinedInput-root {
  color: var(--ion-text-light) !important;
  border-radius: 12px !important;
  // background: rgba(255, 255, 255, 0.04);
}

.MuiInputBase-root {
  color: var(--ion-text-light) !important;
  font-family: 'Alata';
  font-size: 14px;
  height: 40px;
  padding: 0;

  &.Mui-disabled {
    color: var(--text-white) !important;
    background: rgba(255, 255, 255, 0.04) !important;
    border: 1px solid rgba(255, 255, 255, 0.14);
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 14px;
    font-family: 'Alata';
    padding: 14px 16px 15px;
    color: var(--ion-text-light);
  }

  .MuiSvgIcon-root {
    color: var(--ion-text-green-color);
  }
}

.border-input {
  //border: 1px solid var(--filter-controls-border);
  border-radius: 4px;
}

.input-label {
  color: var(--ion-text-light);
  font-family: 'Alata';
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
  display: block;
  padding-left: 5px;
  //@media screen and (max-width: 720px) {
  //padding-bottom: 2px;
  //}
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 3px solid var(--ion-text-green-color);
  color: #18bb4e;
  font-family: var(--headers-font-family);
}
.MuiTab-textColorInherit {
  font-family: var(--headers-font-family);
}
.MuiTab-root {
  text-transform: none;
  font-family: var(--headers-font-family);
}

.MuiTabs-indicator {
  display: none;
}

/** Modal styles **/
.modal-title {
  .MuiTypography-root {
    font-family: var(--headers-font-family) !important;
    font-size: 32px !important;
  }
}

.modal-content-ctn {
  //width: 100%;
  //padding: 0px !important;
  background-color: #ffffff;
  background: #ffffff !important;

  .input-control {
    border: 1px solid rgba(255, 255, 255, 0.14);
    background: rgba(255, 255, 255, 0.04);
  }

  .num-control input:focus {
    outline: none;
  }
}

.mouse-pointer {
  cursor: pointer;
}

/* Punter reports styles */
// .admin-module-ctn {
//   background: var(--ion-bg-table);
//   padding: 6px 16px 50px;
//   min-height: 100vh;
//   border-radius: 24px;
// }

.full-width {
  width: 100%;
}

.no-txt-wrap {
  white-space: nowrap !important;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.b-text {
  font-weight: 600 !important;
}

.profit,
.success {
  color: var(--ion-color-success);
}

.loss,
.error-msg {
  color: var(--ion-color-danger);
}

.m-link {
  cursor: pointer !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
.mobile-footer {
  display: none;
}
@media screen and (max-width: 720px) {
  .mobile-footer {
    display: block;
    background-color: var(--ion-main-body-bg);
    padding-top: 10px;
  }
}
